import React from "react";
import { withStyles } from '@material-ui/core/styles';
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import LinkIcon from '@material-ui/icons/Link';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import IconButton from '@material-ui/core/IconButton';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Tooltip from '@material-ui/core/Tooltip';
import {store} from "react-notifications-component";

//ICONS
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

//COMPONENTES
import DialogUploadContent from "../../../../components/DialogUploadContent/DialogUploadContent";
import DialogAlert from "../../../../components/DialogAlert/DialogAlert";
import FileManager from "../../../../components/FileManager/FileManager";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(16),
    border: '1px solid #dadde9',
    whiteSpace: 'pre-wrap'
  },
}))(Tooltip);

class CardsContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialogAdd:false,
      openAlert:false,
      titleAlert:'',
      textAlert:'',
      fnAlert: () => {},
      categorySelected:{},
      contentSelected:{},
      dataUser:[],
      cardContent:[],
      fileManagerKey:0,
      folderPath:"",
      fileManager:false,
    }
  }

  componentDidMount() {
    const dataUser = JSON.parse(localStorage.getItem("_marketCafeDataUser"));
    this.setState({ dataUser: dataUser }, () => {
      this.getCardContentInfo();
    });
  }

  getCardContentInfo = (reset) => {
    const that = this;
    const { dev, backEnd, handleLoading, cardSelected } = this.props;
    const { dataUser, fileManagerKey } = this.state;
    var userId = dataUser.userId;
    var cadId = cardSelected.cardId;
    const path = cardSelected.cardPath;

    axios.post(
      backEnd + "api/home/cardContentInfo.php",
      {
        dev:dev,
        userId: userId,
        cadId: cadId
      },
      {
        headers: { "Content-Type": "application/json" },
      })
      .then(function (response) {
        handleLoading(false);
        var data = response.data;
        var cardContent = data[0];

        that.setState({ cardContent: cardContent });
        if(reset !== undefined) {
          that.setState({ fileManagerKey:fileManagerKey+1 });
        }
      })
      .catch(function (error) {
        handleLoading(false);
        var e = JSON.parse(JSON.stringify(error));

        var eText = e.message;
        if (eText === "Network Error") {
          eText = "Error de conexión.";
        }
        if (eText === undefined) {
          eText = "Error unknown";
        }
        store.addNotification({
          title: "Error!",
          message: eText,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
  }

  fnDeleteContent = () => {
    const that = this;
    const { handleLoading, backEnd, dev } = this.props;
    const { contentSelected, dataUser, fileManager } = this.state;
    let userId = dataUser.userId;
    let contentId = "";
    let type = "";
    let filePath = "";

    if(fileManager) {
      filePath = contentSelected.path;
    } else {
      contentId = contentSelected.contentId;
      type = contentSelected.contentType;
    }

    handleLoading(true);
    axios.post(
      backEnd + "api/home/deleteContent.php",
      {
        dev:dev,
        userId: userId,
        contentId:contentId,
        type:type,
        filePath:filePath
      },
      {
        headers: { "Content-Type": "application/json" },
      })
      .then(function (response) {
        that.getCardContentInfo(1);
        let data = response.data;
        if (data[0].error === 1) {
          that.handleCloseAlert();
          store.addNotification({
            title: "Success!",
            message: "Content deleted",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        } else {
          store.addNotification({
            title: "Error!",
            message: "There was an error when deleting the content",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        }
      })
      .catch(function (error) {
        handleLoading(false);
        var e = JSON.parse(JSON.stringify(error));

        var eText = e.message;
        if (eText === "Network Error") {
          eText = "Error de conexión.";
        }
        if (eText === undefined) {
          eText = "Error unknown";
        }
        store.addNotification({
          title: "Error!",
          message: eText,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
  }

  fnDeleteCategory = () => {
    const that = this;
    const { handleLoading, backEnd, dev } = this.props;
    const { contentSelected, dataUser, fileManager } = this.state;
    let userId = dataUser.userId;
    let categoryId = "";
    let folderPath = "";
    if(fileManager) {
      folderPath = contentSelected.path;
    } else {
      categoryId = contentSelected.categoryId;
    }

    handleLoading(true);

    axios.post(
      backEnd + "api/home/deleteCategory.php",
      {
        dev:dev,
        userId: userId,
        categoryId:categoryId,
        folderPath:folderPath
      },
      {
        headers: { "Content-Type": "application/json" },
      })
      .then(function (response) {
        that.getCardContentInfo(1);
        let data = response.data;
        console.log(data[0].error);
        if (data[0].error === 1) {
          that.handleCloseAlert();
          store.addNotification({
            title: "Success!",
            message: "Category deleted",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        } else {
          store.addNotification({
            title: "Error!",
            message: "There was an error when deleting the category",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        }
      })
      .catch(function (error) {
        handleLoading(false);
        var e = JSON.parse(JSON.stringify(error));

        var eText = e.message;
        if (eText === "Network Error") {
          eText = "Error de conexión.";
        }
        if (eText === undefined) {
          eText = "Error unknown";
        }
        store.addNotification({
          title: "Error!",
          message: eText,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
  }

  handleGoTo = (url, contentType) => {
    const { backEnd } = this.props;
    if(contentType === "link") {
      window.open(url, '_blank');
    }
    if(contentType === "file") {
      window.open(backEnd+url, '_blank');
    }
  }

  handleOpenDialogAdd = (categorySelected, folderPath) => {
    this.setState({ categorySelected:categorySelected, folderPath:folderPath }, () => {
      this.setState({ openDialogAdd: true });
    });
  }

  handleCloseDialogAdd = () => {
    this.setState({ openDialogAdd:false, folderPath:"" });
  }

  handleOpenAlert = (contentSelected, title, text, fn, fileManager) => {
    this.setState( { contentSelected:contentSelected, fileManager:fileManager }, () => {
      this.setState({ titleAlert:title, textAlert:text, fnAlert:fn, openAlert:true });
    });
  }

  handleCloseAlert = () => {
    this.setState({ openAlert:false });
  }

  handleRreplaceHtml = ( string_to_replace ) => {
    return string_to_replace.replace(/&nbsp;/g, ' ').replace(/<br.*?>/g, '\n');
  }

  render() {
    const that = this;
    const { backEnd, dev, cardSelected, handleBackToHome, handleCheckPermisos, handleLoading } = this.props;
    const { cardContent, categorySelected, openDialogAdd, dataUser, openAlert, titleAlert, textAlert, fnAlert, fileManagerKey, folderPath } = this.state;

    return(
      <>
        { cardSelected.cardName === "Marketing" ?
          <FileManager
            fileManagerKey={fileManagerKey}
            dataUser={dataUser}
            cardSelected={cardSelected}
            backEnd={backEnd}
            dev={dev}
            handleLoading={handleLoading}
            handleGoTo={this.handleGoTo}
            handleCheckPermisos={handleCheckPermisos}
            handleBackToHome={handleBackToHome}
            handleOpenDialogAdd={this.handleOpenDialogAdd}
            handleOpenAlert={this.handleOpenAlert}
            fnDeleteCategory={this.fnDeleteCategory}
            fnDeleteContent={this.fnDeleteContent}
          />
          :
          <>
            <Grid container>
              <Grid item xs={12}>
                <div style={{
                  width:'100%'
                }}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link color="primary" onClick={()=>handleBackToHome()} style={{
                      cursor:'pointer'
                    }}>
                      <Typography variant="h5"> Home </Typography>
                    </Link>
                    <Typography color="textPrimary" variant="h5"> {cardSelected.cardName}</Typography>
                  </Breadcrumbs>
                </div>
              </Grid>
              <Grid item xs={12} style={{textAlign:'right'}}>
                {(handleCheckPermisos(cardSelected.permisos.BtnCrearCategoria.permisoId) || handleCheckPermisos("1")) &&
                <Button
                  variant="contained"
                  color="default"
                  startIcon={<AddCircleIcon />}
                  onClick={()=>that.handleOpenDialogAdd({ newCategory:true })}
                >
                  Add Category
                </Button>
                }
              </Grid>
            </Grid>
            <Grid container spacing={5} style={{
              marginTop:'30px'
            }}>
              {
                cardContent.map(function (arr, index) {
                  return (
                    <Grid key={index} item xs={12} md={4} lg={3} lx={3}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={"panel"+index+"a-content"}
                          id={"panel"+index+"a-header"}
                        >
                          <Typography variant="h5" color="textSecondary" gutterBottom>
                            {(handleCheckPermisos(cardSelected.permisos.BtnBorrarCategoria.permisoId) || handleCheckPermisos("1")) &&
                            <IconButton aria-label="delete" onClick={()=>that.handleOpenAlert(arr, "Delete", "Are you sure to delete the category "+arr.categoryName+"? This will delete all the files under this category.", that.fnDeleteCategory, false)}>
                              <DeleteIcon/>
                            </IconButton>
                            }
                            {arr.categoryName}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <List
                            key={index}
                            component="nav"
                            style={{
                              width:'100%'
                            }}
                          >
                            {(handleCheckPermisos(cardSelected.permisos.BtnSubirContenido.permisoId) || handleCheckPermisos("1")) &&
                            <ListItem style={{
                              justifyContent: 'flex-end'
                            }}>
                              <Button
                                variant="contained"
                                color="default"
                                startIcon={<AddCircleIcon />}
                                onClick={()=>that.handleOpenDialogAdd(arr)}
                              >
                                Add Content
                              </Button>
                            </ListItem>
                            }
                            {
                              arr.categoryContent.map(function (arrC, indexC) {
                                return (
                                  <ListItem button>
                                    <ListItemIcon>
                                      {arrC.contentType === "link" && <LinkIcon />}
                                      {arrC.contentType === "file" && <InsertDriveFileIcon />}
                                    </ListItemIcon>
                                    {arrC.popUpInfo !== "NA" ?
                                      <HtmlTooltip
                                        title={
                                          <React.Fragment>
                                            {that.handleRreplaceHtml(arrC.popUpInfo)}
                                          </React.Fragment>
                                        }
                                      >
                                        <ListItemText primary={arrC.contentName} button
                                                      onClick={() => that.handleGoTo(arrC.contentLinkUrl, arrC.contentType)}/>
                                      </HtmlTooltip>
                                      :
                                      <ListItemText primary={arrC.contentName} button
                                                    onClick={() => that.handleGoTo(arrC.contentLinkUrl, arrC.contentType)}/>
                                    }
                                    <ListItemIcon>
                                      {(handleCheckPermisos(cardSelected.permisos.BtnBorrarContenido.permisoId) || handleCheckPermisos("1")) &&
                                      <IconButton aria-label="delete" onClick={arrC.contentType === "link" ? ()=>that.handleOpenAlert(arrC, "Delete", "Are you sure to delete the link "+arrC.contentName+"?", that.fnDeleteContent, false) : ()=>that.handleOpenAlert(arrC, "Delete", "Are you sure to delete the file "+arrC.contentName+"?", that.fnDeleteContent, false)}>
                                        <DeleteIcon />
                                      </IconButton>
                                      }
                                    </ListItemIcon>
                                  </ListItem>
                                )
                              })
                            }
                          </List>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  )
                })
              }
            </Grid>
          </>
        }
        <DialogUploadContent
          dataUser={dataUser}
          cardSelected={cardSelected}
          backEnd={backEnd}
          dev={dev}
          categorySelected={categorySelected}
          open={openDialogAdd}
          handleClose={this.handleCloseDialogAdd}
          handleLoading={handleLoading}
          getCardContentInfo={this.getCardContentInfo}
          folderPath={folderPath}
        />
        <DialogAlert
          open={openAlert}
          handleClose={this.handleCloseAlert}
          title={titleAlert}
          text={textAlert}
          fn={fnAlert}
        />
      </>
    )
  }
}

export default CardsContent;
