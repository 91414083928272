import React from "react";
import { withStyles } from '@material-ui/core/styles';
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import { store } from "react-notifications-component";
import './FileManager.css';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import AddCircleIcon from "@material-ui/icons/AddCircle";

//ICONS
import DeleteIcon from '@material-ui/icons/Delete';


class FileManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      folderPath: "",
      folderPathRaiz: false
    }
  }

  componentDidMount() {
    this.getScanFiles();
    const path = this.props.cardSelected.cardPath;
    this.setState({ folderPath: "../../assets/docs/"+path  })
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if(nextProps.fileManagerKey !== this.props.fileManagerKey) {
      console.log('recharge');
      this.getScanFiles();
    }
  }

  getScanFiles = () => {
    const that = this;
    const { cardSelected, dataUser, backEnd, dev, handleLoading } = this.props;
    const { folderPath } = this.state;
    const path = cardSelected.cardPath;
    const userId = dataUser.userId;
    console.log(cardSelected);
    handleLoading(true);
    axios.post(
      backEnd + "api/fileManager/scan.php",
      {
        dev:dev,
        userId: userId,
        path: path,
        folderPath:folderPath
      },
      {
        headers: { "Content-Type": "application/json" },
      })
      .then(function (response) {
        handleLoading(false);
        const data = response.data;
        that.setState({ files: data });
        if(folderPath === "../../assets/docs/"+path) {
          that.setState({ folderPathRaiz: false });
        }
      })
      .catch(function (error) {
        handleLoading(false);
        var e = JSON.parse(JSON.stringify(error));

        var eText = e.message;
        if (eText === "Network Error") {
          eText = "Error de conexión.";
        }
        if (eText === undefined) {
          eText = "Error unknown";
        }
        store.addNotification({
          title: "Error!",
          message: eText,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
  }

  handleNavigateFolder = (path) => {
    const { cardSelected } = this.props;
    const pathR = cardSelected.cardPath;
    this.setState({ folderPath:path }, () => {

      if(path !== "../../assets/docs/"+pathR) {
        this.setState({ folderPathRaiz: true });
      }

      this.getScanFiles();
    })
  }

  handleRenderBreadCums = () => {
    const that = this;
    const { cardSelected } = this.props;
    const { folderPath } = this.state;
    const path = cardSelected.cardPath;
    let ret;
    if (folderPath !== "../../assets/docs/"+path) {
      const path = cardSelected.cardPath;
      let data = folderPath.split('assets/docs/' + path + '/');
      let dataS = data[1].split('/');
      ret = dataS.map(function (arr, index) {
        if((index + 1) === dataS.length) {
          return (
            <Typography key={index} color="textPrimary" variant="h5">{arr}</Typography>
          )
        } else {
          let newPath = folderPath.split(arr);
          newPath = newPath[0]+arr;
          return (
            <Link color="inherit" style={{
              cursor:'pointer'
            }}
              onClick={() => that.handleNavigateFolder(newPath)}
            >
              <Typography variant="h5">{ arr }</Typography>
            </Link>
          )
        }
      });
      return ret;
    }
  }

  render() {
    const that = this;
    const { handleGoTo, handleBackToHome, cardSelected, handleCheckPermisos, handleOpenDialogAdd, handleOpenAlert, fnDeleteCategory, fnDeleteContent } = this.props;
    const { files, folderPath, folderPathRaiz } = this.state;

    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <div style={{
              width:'100%'
            }}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="primary" onClick={()=>handleBackToHome()} style={{
                  cursor:'pointer'
                }}>
                  <Typography variant="h5"> Home </Typography>
                </Link>
                {folderPathRaiz ?
                  <Link color="inherit" onClick={() => this.handleNavigateFolder('../../assets/docs/' + cardSelected.cardPath)} style={{
                    cursor:'pointer'
                  }}>
                    <Typography variant="h5">{ cardSelected.cardName }</Typography>
                  </Link>
                  :
                  <Typography color="textPrimary" variant="h5">{cardSelected.cardName}</Typography>
                }
                {folderPathRaiz &&
                  this.handleRenderBreadCums()
                }
              </Breadcrumbs>
            </div>
          </Grid>
          <Grid item xs={12} style={{textAlign:'right'}}>
            {(handleCheckPermisos(cardSelected.permisos.BtnCrearCategoria.permisoId) || handleCheckPermisos("1")) &&
            <Button
              variant="contained"
              color="default"
              startIcon={<AddCircleIcon />}
              onClick={()=>handleOpenDialogAdd({ newCategory:true }, folderPath)}
            >
              New Folder
            </Button>
            }
            {(handleCheckPermisos(cardSelected.permisos.BtnSubirContenido.permisoId) || handleCheckPermisos("1")) &&
            <Button
              variant="contained"
              color="default"
              startIcon={<AddCircleIcon />}
              onClick={()=>handleOpenDialogAdd({ fileManager:true }, folderPath)}
              style={{marginLeft:'10px'}}
            >
              Upload File
            </Button>
            }
          </Grid>
        </Grid>
        <Grid container spacing={5} style={{
          marginTop:'30px'
        }}>
        {
          Object.keys(files).length > 0 &&
          files.items.map(function (arr, index) {
            let fileType = "";
            if(arr.type === "file") {
              fileType = arr.name.split('.');
              fileType = fileType[fileType.length-1];
            }
            return (
              <Grid key={index} item xs={6} lg={2} style={{textAlign:'center'}}>
                { arr.type === "folder" ?
                  <>
                    <div style={{width:'100%', textAlign:'center'}}>
                      {(handleCheckPermisos(cardSelected.permisos.BtnBorrarCategoria.permisoId) || handleCheckPermisos("1")) &&
                      <IconButton aria-label="delete" onClick={()=>handleOpenAlert(arr, "Delete", "Are you sure to delete the category "+arr.name+"? This will delete all the files under this category.", fnDeleteCategory, true)}>
                        <DeleteIcon/>
                      </IconButton>
                      }
                    </div>
                    <Button onClick={() => that.handleNavigateFolder(arr.path)}>
                      <Card style={{
                        boxShadow: 'none', backgroundColor: 'transparent'
                      }}>
                        { arr.items.length > 0 ?
                          <CardContent style={{
                            textAlign: 'center',
                            paddingTop: '0px'
                          }}>
                            <span className="icon folder full"></span>
                            <Typography className="textCenter fileName">{ arr.name }</Typography>
                            <Typography className="textCenter details" variant="h6">{ arr.items.length }
                              { arr.items.length > 1 ? ' files' : ' file'}
                            </Typography>
                          </CardContent>
                          :
                          <CardContent style={{
                            textAlign: 'center',
                            paddingTop: '0px'
                          }}>
                            <span className="icon folder"></span>
                            <Typography className="textCenter fileName">{ arr.name }</Typography>
                          </CardContent>
                        }
                      </Card>
                    </Button>
                  </>
                  :
                  <>
                    <div style={{width:'100%', textAlign:'center'}}>
                      {(handleCheckPermisos(cardSelected.permisos.BtnBorrarContenido.permisoId) || handleCheckPermisos("1")) &&
                      <IconButton aria-label="delete" onClick={()=>handleOpenAlert(arr, "Delete", "Are you sure to delete the file "+arr.name+"?", fnDeleteContent, true)}>
                        <DeleteIcon/>
                      </IconButton>
                      }
                    </div>
                    <Button onClick={() => handleGoTo (arr.path, 'file')}>
                      <Card style={{
                        boxShadow: 'none', backgroundColor: 'transparent'
                      }}>
                        <CardContent style={{
                          textAlign: 'center',
                          paddingTop: '0px'
                        }}>
                          <span className={"icon file f-"+fileType+""}>{fileType}</span>
                          <Typography className="textCenter fileName">{ arr.name }</Typography>
                        </CardContent>
                      </Card>
                    </Button>
                  </>
                }
              </Grid>
          )})
        }
        </Grid>
      </>
    )
  }
}

export default FileManager;
