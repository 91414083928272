import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import NewRelaseIcon from '@material-ui/icons/NewReleasesRounded';
import NewIcon from '@material-ui/icons/FiberNewRounded';
import ListIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import UpdateIcon from '@material-ui/icons/UpdateRounded';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/CloseRounded';

//ASSETS
import logo from "./assets/img/logo.png";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DialogNewVersion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      classes,
      handleClose,
      showVersionImprovements,
      versionLog,
      newVersion,
      open,
      handleUpdateVersion,
      versionImprovements,
      version,
    } = this.props;

    var ver = newVersion;
    var title = (
      <DialogTitle id="simple-dialog-title">
        <div style={{ width: "100%" }}>
          <img
            src={logo}
            className="img-fluid"
            style={{ width: "75px", marginBottom: "15px" }}
            alt="asd"
          />
        </div>
        <div style={{ width: "100%", marginBottom: "10px" }}>
          <span
            className="text-white"
            style={{
              marginTop: "10px",
              fontFamily: "Poppins Medium",
              fontSize: "25px",
              lineHeight: "20px",
            }}
          >
            New version
          </span>
        </div>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          style={{ margin: "auto", borderRadius: "20px" }}
        >
          Improvements {newVersion}
        </Button>
      </DialogTitle>
    );

    var btnActualizar = (
      <Button
        variant="contained"
        color="default"
        className={classes.button}
        style={{ margin: "auto", borderRadius: "20px" }}
        onClick={() => handleUpdateVersion()}
      >
        <UpdateIcon className={classes.leftIcon} />
        Update
      </Button>
    );

    var content = (
      <List style={{ overflow: "hidden" }}>
        {versionImprovements.map(function (row, index) {
          var datosMejoras = row.mejora.split("||");
          return (
            <ListItem key={index}>
              <div className="row" style={{ width: "100%" }}>
                <div className="col-md-12">
                  <span className="text-white" style={{ fontSize: "12px" }}>
                    <NewRelaseIcon style={{ fontSize: "18px" }} /> {row.mejora}
                  </span>
                </div>
              </div>
            </ListItem>
          );
        })}
        <ListItem>{btnActualizar}</ListItem>
      </List>
    );

    if (showVersionImprovements === true) {
      ver = version;
      title = (
        <DialogTitle id="simple-dialog-title">
          <IconButton
            aria-label="Cerrar"
            style={{ position: "absolute", right: "10px" }}
            onClick={() => handleClose()}
          >
            <CloseIcon className="text-white" />
          </IconButton>
          <div style={{ width: "100%" }}>
            <img
              src={logo}
              className="img-fluid"
              style={{ width: "75px", marginBottom: "15px" }}
              alt="asd"
            />
          </div>
          <div style={{ width: "100%", marginBottom: "10px" }}>
            <span
              className="text-white"
              style={{
                marginTop: "10px",
                fontFamily: "Poppins Medium",
                fontSize: "25px",
                lineHeight: "20px",
              }}
            >
              Version log.
            </span>
          </div>
        </DialogTitle>
      );
      btnActualizar = "";

      var vers = "";
      content = (
        <List style={{ overflow: "hidden" }}>
          {versionLog.map(function (row, index) {
            var version = (
              <div className="col-md-12">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  style={{
                    margin: "auto",
                    borderRadius: "20px",
                    marginBottom: "18px",
                  }}
                >
                  {row.version}
                </Button>
              </div>
            );

            if (vers === "") {
              vers = row.version;
            } else if (vers === row.version) {
              version = "";
            } else if (vers !== row.version) {
              vers = row.version;
            }

            var datosMejoras = row.mejora.split("||");
            return (
              <ListItem key={index}>
                <div className="row" style={{ width: "100%" }}>
                  {version}
                  <div className="col-md-12">
                    <span className="text-white" style={{ fontSize: "12px" }}>
                      <NewRelaseIcon style={{ fontSize: "18px" }} /> {row.fecha}
                      : {row.mejora}
                    </span>
                  </div>
                </div>
              </ListItem>
            );
          })}
          <ListItem>{btnActualizar}</ListItem>
        </List>
      );
    }

    return (
      <Dialog
        scroll={"body"}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleClose}
        open={open}
        aria-labelledby="simple-dialog-title"
        TransitionComponent={Transition}
      >
        {title}
        {content}
      </Dialog>
    );
  }
}

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
});

DialogNewVersion.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(DialogNewVersion);
