import React from "react";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';

import { store } from "react-notifications-component";
import CardsContent from "./CardContent/CardContent";

class TabHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataUser:[],
      cards:[],
      cardSelected:{},
      cardsIn:true,
    }
  }

  componentDidMount() {
    const dataUser = JSON.parse(localStorage.getItem("_marketCafeDataUser"));
    const cardSelected = JSON.parse(localStorage.getItem("_marketCafeCardSelected"));
    this.setState({ dataUser: dataUser }, () => {
      if(Object.keys(cardSelected).length > 0) {
        this.setState({cardSelected:cardSelected});
      }
      this.loadHomeInfo();
    });
  }

  componentWillUnmount() {
  }

  loadHomeInfo = () => {
    const that = this;
    const { dev, backEnd, handleLoading } = this.props;
    const { dataUser } = this.state;
    var userId = dataUser.userId;

    handleLoading(true);
    axios.post(
    backEnd + "api/home/homeInfo.php",
    {
      dev:dev,
      userId: userId},
      {
        headers: { "Content-Type": "application/json" },
      })
      .then(function (response) {
        handleLoading(false);
        var data = response.data;
        var cards = data[0];

        that.setState({ cards: cards });
      })
      .catch(function (error) {
        handleLoading(false);
        var e = JSON.parse(JSON.stringify(error));

        var eText = e.message;
        if (eText === "Network Error") {
          eText = "Error de conexión.";
        }
        if (eText === undefined) {
          eText = "Error unknown";
        }
        store.addNotification({
          title: "Error!",
          message: eText,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
  }

  handleSelectCard = (cardSelected) => {
    const that = this;
    const { handleLoading } = this.props;
    localStorage.setItem('_marketCafeCardSelected', JSON.stringify(cardSelected));
    this.setState({ cardsIn:false }, () => {
      handleLoading(true);
      setTimeout(function (){
        that.setState({ cardSelected:cardSelected }, () => {
        });
      },100);
    });
  }

  handleBackToHome = () => {
    localStorage.setItem('_marketCafeCardSelected', JSON.stringify({}));
    this.setState({cardSelected:{}, cardsIn:true});
  }

  render() {
    const that = this;
    const { dev, backEnd, handleLoading, handleCheckPermisos } = this.props;
    const { cards, cardSelected, cardsIn } = this.state;
    return(
      <>
        {Object.keys(cardSelected).length === 0 ?
          <Grid container spacing={5}>
            {
              cards.map(function (arr, index) {
                return (
                  <Grid item xs={12} lg={3}>
                    <Grow
                      in={cardsIn}
                      style={{transformOrigin: '0 0 0'}}
                      timeout={1000}
                    >
                      <Card>
                        <CardActionArea
                          onClick={() => that.handleSelectCard(arr)}
                        >
                          <CardMedia
                            style={{
                              height: '200px'
                            }}
                            image={backEnd + arr.cardImg}
                            title={arr.cardName}
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                              {arr.cardName}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grow>
                  </Grid>
                )
              })
            }
          </Grid>
          :
          <CardsContent
            backEnd={backEnd}
            dev={dev}
            cardSelected={cardSelected}
            handleBackToHome={this.handleBackToHome}
            handleLoading={handleLoading}
            handleCheckPermisos={handleCheckPermisos}
          />
        }
      </>
    )
  }
}

export default TabHome;
