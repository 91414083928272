import React from "react";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';
import MaterialTable from "material-table";
import { store } from "react-notifications-component";

class TabDirectory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataUser:[],
      directory:[],
    }
  }

  componentDidMount() {
    const dataUser = JSON.parse(localStorage.getItem("_marketCafeDataUser"));
    const cardSelected = JSON.parse(localStorage.getItem("_marketCafeCardSelected"));
    this.setState({ dataUser: dataUser }, () => {
      if(Object.keys(cardSelected).length > 0) {
        this.setState({cardSelected:cardSelected});
      }
      this.loadDirectoryInfo();
    });
  }

  componentWillUnmount() {
  }

  loadDirectoryInfo = () => {
    const that = this;
    const { dev, backEnd, handleLoading } = this.props;
    const { dataUser } = this.state;
    var userId = dataUser.userId;

    handleLoading(true);
    axios.post(
    backEnd + "api/companyDirectory/companyDirectoryInfo.php",
    {
      dev:dev,
      userId: userId},
      {
        headers: { "Content-Type": "application/json" },
      })
      .then(function (response) {
        handleLoading(false);
        var data = response.data;
        var directory = data[0];

        that.setState({ directory: directory });
      })
      .catch(function (error) {
        handleLoading(false);
        var e = JSON.parse(JSON.stringify(error));

        var eText = e.message;
        if (eText === "Network Error") {
          eText = "Error de conexión.";
        }
        if (eText === undefined) {
          eText = "Error unknown";
        }
        store.addNotification({
          title: "Error!",
          message: eText,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
  }

  render() {
    const that = this;
    const { dev, backEnd, handleLoading } = this.props;
    const { directory } = this.state;
    return(
      <>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <MaterialTable
              data={directory}
              options={{
                sorting: true,
                pageSize:10,
                pageSizeOptions:[10,25,50,100],
                rowStyle: {
                  textAlign: "center",
                },
              }}
              title="Company Directory"
              columns={[
                { title: "ID", field: "loanId", editable: "never", hidden: true },
                {
                  title: "Name",
                  field: "nombreCompleto",
                  cellStyle: { textAlign: "left" },
                  headerStyle: { color:'#F9BA5C', borderBottom:'1px solid #585858' },
                  render: (rowData) => {
                    return (
                      <font
                        style={{ cursor: "pointer", textDecoration: "none", color:'#585858' }}
                      >
                        {rowData.nombreCompleto}
                      </font>
                    );
                  },
                },
                {
                  title: "Email",
                  field: "email",
                  cellStyle: { textAlign: "left" },
                  headerStyle: { color:'#F9BA5C', borderBottom:'1px solid #585858' },
                  render: (rowData) => {
                    return (
                      <a href={"mailto: "+rowData.email}>
                      <font
                        style={{ cursor: "pointer", textDecoration: "underline", color:'#585858' }}
                      >
                        {rowData.email}
                      </font>
                      </a>
                    );
                  },
                },
                {
                  title: "Phone Number",
                  field: "cellPhoneNumber",
                  cellStyle: { textAlign: "left" },
                  headerStyle: { color:'#F9BA5C', borderBottom:'1px solid #585858' },
                  render: (rowData) => {
                    return (
                      <a href={"tel: "+rowData.cellPhoneNumber}>
                        <font
                          style={{ cursor: "pointer", textDecoration: "underline", color:'#585858' }}
                        >
                          {rowData.cellPhoneNumber}
                        </font>
                      </a>
                    );
                  },
                },
                {
                  title: "Job Title",
                  field: "puesto",
                  cellStyle: { textAlign: "left" },
                  headerStyle: { color:'#F9BA5C', borderBottom:'1px solid #585858' },
                  render: (rowData) => {
                    return (
                      <font
                        style={{ cursor: "pointer", textDecoration: "none", color:'#585858' }}
                      >
                        {rowData.puesto}
                      </font>
                    );
                  },
                },
                ]}
              />
          </Grid>
        </Grid>
      </>
    )
  }
}

export default TabDirectory;
