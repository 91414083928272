import React from "react";
import PropTypes from 'prop-types';
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

//ICONS
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import FormatListBulletedRoundedIcon from '@material-ui/icons/FormatListBulletedRounded';
import GroupWorkRoundedIcon from '@material-ui/icons/GroupWorkRounded';

//COMPONENTES
import TabHome from "./TabHome/TabHome";
import TabDirectory from "./TabDirectory/TabDirectory";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:false,
      value:0,
    }
  }

  handleChange = (event, newValue) => {
    this.setState({value:newValue}, () => {
      if(newValue === 0) {
        localStorage.setItem('_marketCafeCardSelected', JSON.stringify({}));
      }
    });
  };

  handleLoading = (option) => {
    this.setState({loading:option});
  }

  render() {
    const { dev, backEnd, pageHeight, handleCheckPermisos } = this.props;
    const { loading, value } = this.state;

    return (
      <>
        <div
          className="main"
          style={{
            height: pageHeight + "px",
            marginTop: "0px",
            maxHeight: pageHeight + "px",
            overflowY: "auto",
            backgroundColor:'#F5F5F5'
          }}
        >
          <AppBar
            position="static"
            elevation={0}
            style={{ marginTop: "64px", backgroundColor:'#585858' }}
          >
            <Tabs
              value={value}
              onChange={this.handleChange}
              aria-label="simple tabs example"
              indicatorColor="secondary"
            >
              <Tab label="Home" icon={<HomeRoundedIcon/>} {...a11yProps(0)} />
              {/*<Tab label="Branchlist" icon={<FormatListBulletedRoundedIcon/>} {...a11yProps(1)} />*/}
              <Tab label="Company Directory" icon={<GroupWorkRoundedIcon/>} {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <TabHome
              dev={dev}
              backEnd={backEnd}
              handleLoading={this.handleLoading}
              handleCheckPermisos={handleCheckPermisos}
            />
          </TabPanel>
          {/*<TabPanel value={value} index={1}>
            <div></div>
          </TabPanel>*/}
          <TabPanel value={value} index={2}>
            <TabDirectory
              dev={dev}
              backEnd={backEnd}
              handleLoading={this.handleLoading}
              handleCheckPermisos={handleCheckPermisos}
            />
          </TabPanel>
        </div>
        <Backdrop open={loading} style={{zIndex:1000000000}}>
          <CircularProgress style={{ color: "#FFF" }} />
        </Backdrop>
      </>
    );
  }
}
export default HomePage;
