/*Componentes globales*/
import React, {Component, PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';

/*Componentes de la aplicación*/
import {FilePond, File, registerPlugin} from 'react-filepond';
import 'filepond/dist/filepond.min.css';

import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginFileRename from 'filepond-plugin-file-rename';
import * as Doka from './doka-4.1.2/bin/browser/doka.min';
import './doka-4.1.2/bin/browser/doka.min.css';

import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

registerPlugin(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginImageExifOrientation, FilePondPluginImageCrop, FilePondPluginImageResize, FilePondPluginImageTransform, FilePondPluginImageEdit, FilePondPluginFileRename);

class DokaManager extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    }
  }
  onprocessfile
  render() {

    const {type, backEnd, urlServerUpload, onprocessfile, acceptedFileTypes, fileRenameFunction, onremovefile, disabled} = this.props;

    if (type === "file") {
      return (
        <FilePond
          disabled={disabled}
          server={{
            url: backEnd,
            process: "./" + urlServerUpload,
            fetch: "",
            load: ""
          }}
          labelIdle={
            !disabled ?
            [
              'Drag and drop your file or <span class="filepond--label-action">search it</span>'
            ]
              :
              'Please write your file name before'
          }
          instantUpload={true}
          onprocessfile={onprocessfile}
          onremovefile={onremovefile}
          fileRenameFunction={fileRenameFunction}
        />
      )
    }
  }
}


export default DokaManager;
