import {prepareRoutes} from "@curi/router";

//PAGINAS
import LoginPage from "./pages/Login/LoginPage";
import HomePage from "./pages/Home/HomePage";

export default prepareRoutes([
  {
    name: "login",
    path: "login",
    response() {
      return {body: LoginPage};
    }
  },
  {
    name: "home",
    path: "home",
    response() {
      return {body: HomePage};
    }
  },
  {
    name: "Catch All",
    path: "(.*)",
    response() {
      return {body: LoginPage};
    }
  }
]);
