import React from "react";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import {store} from "react-notifications-component";
import DokaManager from "../../commons/DokaManager";

//ICONS
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class DialogUploadContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  get initialState() {
    return {
      keyDoka:0,
      option: 'file',
      fileName: '',
      url: '',
      urlE: false,
      optionE: false,
      fileNameE: false,
      disableBtn: true,
      categoryName: '',
      categoryNameE: false
    }
  }

  getFileId = (error, file) => {
    console.log(file);
    console.log(error);
    this.setState({ disableBtn:false });
  }

  onRemoveFile = (error, file) => {

  }

  fnCreateLink = () => {
    const that = this;
    const { dataUser, handleLoading, backEnd, dev, categorySelected } = this.props;
    const { fileName, url } = this.state;
    let userId = dataUser.userId;
    let categoryId = categorySelected.categoryId;
    handleLoading(true);
    axios.post(
      backEnd + "api/home/createLink.php",
      {
        dev:dev,
        userId: userId,
        categoryId:categoryId,
        fileName:fileName,
        url:url
      },
      {
        headers: { "Content-Type": "application/json" },
      })
      .then(function (response) {
        handleLoading(false);
        let data = response.data;
        if (data[0].error === 1) {
          that.handleClose();
          store.addNotification({
            title: "Success!",
            message: "Link created",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        } else {
          store.addNotification({
            title: "Error!",
            message: "There was an error when creating the link",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        }
      })
      .catch(function (error) {
        handleLoading(false);
        var e = JSON.parse(JSON.stringify(error));

        var eText = e.message;
        if (eText === "Network Error") {
          eText = "Error de conexión.";
        }
        if (eText === undefined) {
          eText = "Error unknown";
        }
        store.addNotification({
          title: "Error!",
          message: eText,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
  }

  fnCrearCategoria = () => {
    const that = this;
    const { dataUser, handleLoading, backEnd, dev, cardSelected, folderPath } = this.props;
    const { categoryName } = this.state;
    let userId = dataUser.userId;
    let cardId = cardSelected.cardId;

    handleLoading(true);
    axios.post(
      backEnd + "api/home/createCategory.php",
      {
        dev:dev,
        userId: userId,
        categoryName:categoryName,
        cardId:cardId,
        folderPath:folderPath
      },
      {
        headers: { "Content-Type": "application/json" },
      })
      .then(function (response) {
        handleLoading(false);
        let data = response.data;
        if (data[0].error === 1) {
          that.handleClose();
          store.addNotification({
            title: "Success!",
            message: "Category created",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        } else {
          store.addNotification({
            title: "Error!",
            message: "There was an error when creating the category",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        }
      })
      .catch(function (error) {
        handleLoading(false);
        var e = JSON.parse(JSON.stringify(error));

        var eText = e.message;
        if (eText === "Network Error") {
          eText = "Error de conexión.";
        }
        if (eText === undefined) {
          eText = "Error unknown";
        }
        store.addNotification({
          title: "Error!",
          message: eText,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      });
  }

  handleChange = name => event => {
    this.setState({[name]: event.target.value, [name + 'E']: false}, () => {
      const { fileName, url, option } = this.state;
      if (option === "url") {
        if (fileName !== "" && url !== "") {
          console.log("1")
          this.setState({ disableBtn:false });
        } else {
          console.log("2")
          this.setState({ disableBtn:true });
        }
      }
    });
  };

  handleClose = () => {
    const { handleClose, getCardContentInfo } = this.props;
    const { keyDoka } = this.state;
    this.setState(this.initialState, ()=> {
      this.setState({ keyDoka:keyDoka+1 });
      getCardContentInfo(1);
      handleClose();
    });
  }

  handleRenderTitle = () => {
    const { folderPath } = this.props;
    const folderPathArray = folderPath.split('/');
    const lastItem = folderPathArray[folderPathArray.length - 1]
    console.log(lastItem);
    return lastItem;
  }

  render() {
    const { backEnd, dev, categorySelected, cardSelected, open, handleClose, dataUser, folderPath } = this.props;
    const { option, optionE, fileName, fileNameE, disableBtn, keyDoka, urlE, url, categoryName, categoryNameE } = this.state;
    return (
      <Dialog
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        fullWidth={true}
        maxWidth="md"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        {!categorySelected.newCategory && !categorySelected.fileManager &&
        <>
          <DialogTitle>Add new content to <b>{categorySelected.categoryName}</b></DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  error={optionE}
                  select
                  id="option"
                  label="Option"
                  variant="outlined"
                  onChange={this.handleChange("option")}
                  value={option}
                  style={{
                    width: "100%",
                  }}
                >
                  <MenuItem value="file" color="secondary">
                    New File
                  </MenuItem>
                  <MenuItem value="url">
                    New Link URL
                  </MenuItem>
                </TextField>
              </Grid>
              {
                option === "file" &&
                <>
                  <Grid item xs={12}>
                    <TextField
                      error={fileNameE}
                      id="fileName"
                      label="File name"
                      variant="outlined"
                      onChange={this.handleChange("fileName")}
                      value={fileName}
                      style={{
                        width: "100%",
                      }}
                      helperText={'Only write the file name without the .extension file'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DokaManager
                      key={keyDoka}
                      disabled={fileName === ""}
                      type="file"
                      backEnd={backEnd}
                      urlServerUpload={"api/uploadManager/index.php?option=file&cardId=" + categorySelected.cardId + "&categoryId=" + categorySelected.categoryId + "&cardPath=" + cardSelected.cardPath + "&userId=" + dataUser.userId}
                      onprocessfile={(error, file) => this.getFileId(error, file)}
                      onremovefile={this.onRemoveFile}
                      fileRenameFunction={(file) => {
                        return fileName + file.extension
                      }}
                    />
                  </Grid>
                </>
              }
              {
                option === "url" &&
                <>
                  <Grid item xs={12}>
                    <TextField
                      error={fileNameE}
                      id="fileName"
                      label="Link Name"
                      variant="outlined"
                      onChange={this.handleChange("fileName")}
                      value={fileName}
                      style={{
                        width: "100%",
                      }}
                      helperText={'Write a name for your link'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={urlE}
                      id="url"
                      label="Url"
                      variant="outlined"
                      onChange={this.handleChange("url")}
                      value={url}
                      style={{
                        width: "100%",
                      }}
                      helperText={'Write the url'}
                    />
                  </Grid>
                </>
              }
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            {option === 'file' ?
              <Button
                variant="contained"
                color="primary"
                disabled={disableBtn}
                onClick={() => this.handleClose()}
              >
                OK
              </Button>
              :
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddCircleIcon/>}
                disabled={disableBtn}
                onClick={() => this.fnCreateLink()}
              >
                Create Link
              </Button>
            }
          </DialogActions>
        </>
        }
        {categorySelected.fileManager &&
        <>
          <DialogTitle>Upload new file in <b>{ this.handleRenderTitle() }</b></DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  error={fileNameE}
                  id="fileName"
                  label="File name"
                  variant="outlined"
                  onChange={this.handleChange("fileName")}
                  value={fileName}
                  style={{
                    width: "100%",
                  }}
                  helperText={'Only write the file name without the .extension file'}
                />
              </Grid>
              <Grid item xs={12}>
                <DokaManager
                  key={keyDoka}
                  disabled={fileName === ""}
                  type="file"
                  backEnd={backEnd}
                  urlServerUpload={"api/uploadManager/index.php?option=file&folderPath=" + encodeURIComponent(folderPath) + "&userId=" + dataUser.userId}
                  onprocessfile={(error, file) => this.getFileId(error, file)}
                  onremovefile={this.onRemoveFile}
                  fileRenameFunction={(file) => {
                    return fileName + file.extension
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={disableBtn}
              onClick={() => this.handleClose()}
            >
              OK
            </Button>
          </DialogActions>
        </>
        }
        {categorySelected.newCategory &&
        <>
          <DialogTitle>Add new category</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  error={categoryNameE}
                  id="categoryName"
                  label="Category name"
                  variant="outlined"
                  onChange={this.handleChange("categoryName")}
                  value={categoryName}
                  style={{
                    width: "100%",
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={categoryName === ""}
              onClick={() => this.fnCrearCategoria()}
              startIcon={<AddCircleIcon />}
            >
              Create
            </Button>
          </DialogActions>
        </>
        }
      </Dialog>
    )
  }
}

export default DialogUploadContent;
